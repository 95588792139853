import React, {Component} from 'react';
import Login from "../components/Login/Login";

class LoginView extends Component {
  render() {
    return (
      <div>
        <Login title="Sign In"/>
      </div>

    );
  }
}

export default LoginView;