import utils from "../../../../../Shared/Utils/utils";

export const dashboardBuild = [
  {
    type: "simpleCount",
    width: 6,
    countTitle: "People vaccinated",
    includeChartTitle: true,
    chartTitle: "",
    dataTransformer: "simpleCount",
    dataType: "lastTotals",
    downloadableImage: true,
    downloadableCsv: true,
    main: 'SUM_people_vaccinated',
    secondary: 'SUM_people_fully_vaccinated',
    secondaryTitle: 'Fully vaccinated:',
    height: 200,
  },
  {
    type: "simpleCount",
    width: 6,
    countTitle: "Distributed vaccines",
    includeChartTitle: true,
    chartTitle: "",
    dataTransformer: "simpleCount",
    dataType: "lastTotals",
    downloadableImage: true,
    downloadableCsv: true,
    main: 'SUM_total_distributed',
    secondary: 'SUM_total_vaccinations',
    secondaryTitle: 'Total vaccinations',
    height: 200,
  },
  {
    type: "text",
    title: "Vaccinations per State (%)",
    height: 50
  },
  {
    type: "map",
    width: 8,
    mapType: "cloropleth",
    center: [37.0902, -95.7129],
    zoom: 4,
    dataTransformer: (data, chart) =>
    (data &&
        data.items &&
        data.items.map((item) => ({
            id: item[chart.groupBy],
            value: chart.countField ? item[chart.countField] : item.COUNT,
        }))) ||
    [],
    dataType: "vaccinationPercent",
    downloadableImage: true,
    chartTitle: "",
    groupBy: 'location',
    geoJsonURL: '/data/us_states.geo.json',
    geoJsonIdField: 'stateName',
    countField: 'people_vaccinated_per_hundred'
  },
  {
    type: "barChart",
    width: 4,
    layout: "horizontal",
    chartTitle: "",
    dataTransformer: "barChart",
    dataType: "vaccinationPercent",
    legendAnchor: "bottom-right",
    downloadableImage: true,
    downloadableCsv: true,
    groupBy: "location",
    yLegend: "People Vaccinated (%)",
    xLegend: "State",
    enableGridX: true,
    withBrush: true,
    countField: 'people_vaccinated_per_hundred'
  },
  {
    type: "divider",
  },
  {
    type: "text",
    title: "Timelines",
    height: 50
  },
  {
    type: 'lineChart',
    width: 12,
    chartTitle: 'People Vaccination Timeline',
    dataTransformer: 'timeline',
    dataType: 'timeline',
    countTitle: 'Count',
    categories: ['SUM_people_vaccinated', 'SUM_people_fully_vaccinated']
  },
  {
    type: 'multiAxis',
    width: 12,
    chartTitle: '',
    dataTransformer: (data, chart) => {
        let categories = [];
        let series = [];

        if (data && data.items) {
            const sortedData = data.items.sort((a, b) => {
                if (a.date < b.date) {
                    return -1;
                }
                if (a.date > b.date) {
                    return 1;
                }
                return 0;
            });

            categories = sortedData.map((i) => i.date);

            series = chart.barCategories.reduce((accum, key) => {
                accum.push({
                    name: utils.toTitleText(key.replaceAll('SUM_', '').replaceAll('_', ' ')),
                    type: 'column',
                    data: sortedData.map((i) => i[key]),
                });

                return accum;
            }, series);

            series = chart.lineCategories.reduce((accum, key) => {
                accum.push({
                    name: utils.toTitleText(key.replaceAll('SUM_', '').replaceAll('_', ' ')),
                    type: 'line',
                    data: sortedData.map((i) => i[key]),
                });

                return accum;
            }, series);
        }
        const returnData = {
            series,
            categories,
        };

        return returnData;
    },
    dataType: 'timeline',
    countTitle: 'Count',
    lineCategories: ['SUM_total_distributed', 'SUM_total_vaccinations'],
    barCategories: ['SUM_daily_vaccinations_raw'],
    rightYTitle: "Daily Vaccinations",
    rightYSeries: "SUM_daily_vaccinations_raw",
    leftYTitle: "Count",
  },
];
