const fields = [
  {
    id: "condition",
    type: "typeahead",
    label: "Condition",
  },
  {
    id: "country",
    type: "typeahead",
    label: "Country",
  },
  {
    id: "responsible",
    type: "typeahead",
    label: "Responsible",
  },
  {
    id: "start_date",
    type: "date",
    label: "Start Date",
  },
  {
    id: "overall_status",
    type: "list",
    label: "Status",
  },
  {
    id: "study_type",
    type: "list",
    label: "Study Type",
  },
  {
    id: "phase",
    type: "list",
    label: "Phase",
  },
  {
    id: "has_dmc",
    type: "list",
    label: "Document Management Committee",
  },
  {
    id: "is_fda_regulated_drug",
    type: "list",
    label: "FDA Regulated Drug",
  },
  {
    id: "is_fda_regulated_device",
    type: "list",
    label: "FDA Regulated Device",
  },
];

export default fields;
