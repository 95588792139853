export const queries = {
  lastRecordedDate: {
    groupBy: ['date'],
    sortAsc: false,
    limit: 1,
    sortField: 'date'
  },
  timeline: {
    groupBy: ['date'],
    addToGroupBy: ['SUM(total_distributed)', 'SUM(total_vaccinations)', 'SUM(people_vaccinated)', 'SUM(people_fully_vaccinated)', 'SUM(daily_vaccinations_raw)'],
    limit: 0,
    initialFilter: {
      location: {
        type: 'STRING',
        nin: [
          'United States',
          'Puerto Rico',
          'Federated States of Micronesia',
          'Veterans Health',
          'Long Term Care',
          'Indian Health Svc',
          'Dept of Defense',
          'Bureau of Prisons'
        ]
      },
    },
  },
};

export const dependentQueries = {
  lastTotals: {
    groupBy: ['date'],
    addToGroupBy: ['SUM(people_vaccinated)', 'SUM(people_fully_vaccinated)','SUM(total_distributed)', 'SUM(total_vaccinations)'],
    limit: 0,
    initialFilter: {
      location: {
        type: 'STRING',
        nin: [
          'United States',
          'Puerto Rico',
          'Federated States of Micronesia',
          'Veterans Health',
          'Long Term Care',
          'Indian Health Svc',
          'Dept of Defense',
          'Bureau of Prisons'
        ]
      },
      date: {
        type: 'STRING',
        eq: 'latestRecordedDate'
      },

    },
  },
  vaccinationPercent: {
    fields: ["location", "people_vaccinated_per_hundred"],
    limit: 0,
    initialFilter: {
      date: {
        type: 'STRING',
        eq: 'latestRecordedDate'
      },
    }
  },
};