import React from "react";
import Iframe from "react-iframe";

//redux additions
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as trialActions from "./TrialActions";

import Breadcrumbs from "../Shared/Breadcrumbs";

// material- ui
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

import DashboardStyles from "../../assets/jss/Components/DashboardStyles";
import { CircularProgress } from "@material-ui/core";

const API_ROOT = process.env.REACT_APP_API_ROOT;

const TrialFrame = props => {
	const { classes, trialId, trial } = props;

	React.useEffect(() => {
		if (!trial || (trial && !trial.view && !trial.loading)) {
			// Load counties
			props.viewTrial(trialId);
		}
	}, [props, trial, trialId]);

	//subnav containing breadcrumbs
	const subnav = (
		<Grid container alignItems={"center"} className={classes.subnav}>
			<Grid item style={{ flexGrow: 1 }}>
				<Breadcrumbs trialId={trialId} />
			</Grid>
		</Grid>
	);

	console.log("Trial Content", trial && trial.view);

	return (
		<Grid container justify={"center"}>
			<Grid item xs={11}>
				{subnav}
			</Grid>
			<Grid xs={11}>
				{!trial || trial.loading ? (
					<CircularProgress />
				) : (
					<Iframe
						src={
							trial.view
								? `data:text/html;charset=utf-8,${escape(trial.view)}`
								: ""
						}
						id="sisense_iFrame"
						className={classes.iframe}
					/>
				)}
			</Grid>
		</Grid>
	);
};

const mapStateToProps = state => {
	return {
		trial: state.trial
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(trialActions, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(DashboardStyles)(TrialFrame));
