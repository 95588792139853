import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import LoginOktaSignInWidget from './LoginOktaSignInWidget';
import { withAuth } from '@okta/okta-react';
import {checkAuthentication} from "./LoginCheckAuth";

export default withAuth(class Login extends Component {
  constructor(props) {
    super(props);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.state = {
      authenticated: null,
      token: null
    };
    this.checkAuthentication = checkAuthentication.bind(this);
  }

  // once the Login component is mounted redirect url is stored in localstorage and user data is authenticated setting
  // the data with userinfo.
  componentDidMount() {
    this.checkAuthentication();
  }

  // set props to redirect authenticated user
  onSuccess(res) {
    if (res.status === 'SUCCESS') {
      return this.props.auth.redirect({
        sessionToken: res.session.token
      });
    }
  }

  onError(err) {
    console.log('error logging in', err);
  }

  render() {
    const {authenticated} = this.state;

    // if the user is not authenticated
    if (authenticated === null) return null;
    return (
      <div>
        {!authenticated &&
        <LoginOktaSignInWidget
          onSuccess={this.onSuccess}
          onError={this.onError}
        />
        }
        {/* redirects to '/dashboard' when user is authenticated */}
        {authenticated &&
        <Redirect to={{pathname: '/'}}/>
        }
      </div>
    )
  }
});

