import React from "react";
import utils from '../../../../../Shared/Utils/utils';

export const dashboardBuild = [
  {
    type: "simpleCount",
    width: 2,
    chartTitle: "Number of Trials",
    countTitle: "Number of Trials",
    dataTransformer: "simpleCount",
    dataType: "reportCounts",
    includeChartTitle: true,
    main: "COUNT",
  },
  {
    type: "barChart",
    width: 10,
    layout: "horizontal",
    chartTitle: "Study Type",
    dataTransformer: "barChart",
    dataType: "byStudyTypePerPurpose",
    legendAnchor: "bottom-right",
    downloadableImage: true,
    downloadableCsv: true,
    groupBy: "study_type",
    itemBy: "primary_purpose",
    yLegend: "Number of Clinical Trials",
    enableGridX: true,
    showBrush: true,
  },
  {
    type: "pieChart",
    width: 3,
    chartType: "donut",
    chartTitle: "Study Type",
    dataTransformer: "pieChart",
    dataType: "byStudyType",
    showPercent: true,
    downloadableImage: true,
    downloadableCsv: true,
    legend: true,
    legendAnchor: "top-left",
    enableRadialLabels: false,
    filterBy: "study_type",
  },
  {
    type: "pieChart",
    width: 3,
    chartType: "donut",
    chartTitle: "Trials per Status",
    dataTransformer: "pieChart",
    dataType: "byStatus",
    showPercent: true,
    downloadableImage: true,
    downloadableCsv: true,
    legend: true,
    legendAnchor: "top-left",
    enableRadialLabels: false,
    filterBy: "status",
  },
  {
    type: "pieChart",
    width: 3,
    chartType: "donut",
    chartTitle: "Trials per Phase",
    dataTransformer: "pieChart",
    dataType: "byPhase",
    showPercent: true,
    downloadableImage: true,
    downloadableCsv: true,
    legend: true,
    legendAnchor: "top-left",
    enableRadialLabels: false,
    filterBy: "phases",
  },
  {
    type: "pieChart",
    width: 3,
    chartType: "donut",
    chartTitle: "Results",
    dataTransformer: "pieChart",
    dataType: "byResults",
    showPercent: true,
    downloadableImage: true,
    downloadableCsv: true,
    legend: true,
    legendAnchor: "top-left",
    enableRadialLabels: false,
    filterBy: "study_results",
  },
  {
    type: 'lineChart',
    width: 12,
    chartTitle: 'Trials Started',
    dataTransformer: (data, chart) => {
      return { 
        items: chart.categories.reduce(
          (accum, c) => [
              ...accum,
              {
                  id: utils.toTitleText(c.replaceAll('SUM_', '').replaceAll('_', ' ')),
                  data:
                      (data &&
                          data.items &&
                          data.items.length > 0 &&
                          data.items
                              .map((item) => ({
                                  x: chart.groupBy ? item[chart.groupBy] : item.date,
                                  y: item[c],
                              }))
                              .sort((a, b) => {
                                  if (a.x < b.x) {
                                      return -1;
                                  }
                                  if (a.x > b.x) {
                                      return 1;
                                  }
                                  return 0;
                              })) ||
                      [],
              },
          ],
          [],
      ),
    };
  },
    dataType: 'startTimeline',
    countTitle: 'Count',
    groupBy: 'start_date',
    categories: ['COUNT']
  },
  {
    type: "tableComponent",
    width: 12,
    dataTransformer: "tableComponent",
    chartTitle: "List Clinical Trials",
    dataType: "tableData",
    download: false,
    downloadableCsv: true,
    elevation: 0,
    filter: false,
    print: false,
    pagination: true,
    search: false,
    selectableRows: false,
    sort: true,
    rowsPerPageOptions: [10, 25, 50],
    viewColumns: false,
    serverSide: true,
    rowClick: true,
    idField: 'url',
    showIdField: false,
    conversions: {
      url: (v) => (
        <a href={v} target="_blank" rel="noopener noreferrer">
          {v}
        </a>
      ),
    },
  },
];
