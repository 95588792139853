import utils from '../../../../../Shared/Utils/utils';

export const dashboardBuild = [
  {
    type: "simpleCount",
    width: 4,
    chartTitle: "Number of Trials",
    countTitle: "Number of Trials",
    dataTransformer: "simpleCount",
    dataType: "reportCounts",
    includeChartTitle: false,
    main: "COUNT",
    height: 200,
  },
  {
    type: "simpleCount",
    width: 4,
    chartTitle: "Number of Responsible Parties",
    countTitle: "Number of Responsible Parties",
    dataTransformer: (data, chart) =>
    data &&
    data.items &&
    data.items.length > 0 &&
    (chart.main && chart.secondary
        ? {
              primary: data.items[0][chart.main],
              secondary: data.items[0][chart.secondary],
          }
        : {
              primary: data.items.length,
          }),
    dataType: "responsibles",
    includeChartTitle: false,
    height: 200,
  },
  {
    type: "simpleCount",
    width: 4,
    chartTitle: "Trials Started this year ",
    countTitle: `Trials Started this year (${new Date().getFullYear()})`,
    dataTransformer: "simpleCount",
    dataType: "yearTrials",
    includeChartTitle: false,
    main: "COUNT",
    height: 200,
  },
  {
    type: "wordCloud",
    width: 6,
    chartTitle: "Top Conditions",
    dataTransformer: "wordCloud",
    dataType: "byCondition",
    groupBy: "condition",
    downloadableImage: true,
    downloadableCsv: true,
  },
  {
    type: "barChart",
    width: 6,
    layout: "horizontal",
    chartTitle: "Top Countries",
    dataTransformer: "barChart",
    dataType: "topCountries",
    legendAnchor: "bottom-right",
    downloadableImage: true,
    downloadableCsv: true,
    groupBy: "country",
    yLegend: "Number of Clinical Trials",
    enableGridX: true,
    showBrush: false,
  },
  {
    type: "calendar",
    width: 12,
    chartTitle: 'Trials Started Over Past 2 Years',
    dataTransformer: (data, chart) =>
    (data &&
        data.items &&
        data.items.map((dayItem) => ({
            day: dayItem[chart.groupBy].split('T')[0],
            value: dayItem.COUNT,
        }))) ||
    [],
    dataType: "timeline",
    legendAnchor: "bottom-right",
    downloadableImage: true,
    downloadableCsv: true,
    groupBy: "start_date",
    height: 300,
    filterBy: 'start_date',
  },
];

export const insightsBuild = [
  {
    type: "barChart",
    width: 6,
    layout: "horizontal",
    chartTitle: "Trials per Country And Type",
    dataTransformer: "barChart",
    dataType: "byCountryAndType",
    legendAnchor: "bottom-right",
    downloadableImage: true,
    downloadableCsv: true,
    groupBy: "country",
    itemBy: "study_type",
    yLegend: "Number of Clinical Trials",
    enableGridX: true,
    withBrush: true,
  },
  {
    type: "pieChart",
    width: 6,
    chartTitle: "Status",
    dataTransformer: "pieChart",
    dataType: "byStatus",
    showPercent: true,
    downloadableImage: true,
    downloadableCsv: true,
    legend: true,
    legendAnchor: "top-left",
    enableRadialLabels: false,
    filterBy: 'overall_status',
  },
  {
    type: "barChart",
    layout: "horizontal",
    width: 4,
    chartTitle: "Phase",
    dataTransformer: "barChart",
    dataType: "byPhase",
    downloadableImage: true,
    downloadableCsv: true,
    groupBy: "phase",
  },
  {
    type: "barChart",
    layout: "horizontal",
    width: 4,
    chartTitle: "Status",
    dataTransformer: "barChart",
    dataType: "byStatus",
    showPercent: true,
    downloadableImage: true,
    downloadableCsv: true,
    groupBy: "overall_status",
  },
  {
    type: "pieChart",
    width: 4,
    chartType: "donut",
    chartTitle: "Study Type",
    dataTransformer: "pieChart",
    dataType: "byStudyType",
    showPercent: true,
    downloadableImage: true,
    downloadableCsv: true,
    legend: true,
    legendAnchor: "top-left",
    enableRadialLabels: false,
    filterBy: "study_type",
  },
  {
    type: "pieChart",
    width: 4,
    chartType: "donut",
    chartTitle: "Data Management Committee",
    dataTransformer: "pieChart",
    dataType: "byDMC",
    showPercent: true,
    downloadableImage: true,
    downloadableCsv: true,
    legend: true,
    legendAnchor: "top-left",
    enableRadialLabels: false,
    filterBy: "has_dmc",
  },
  {
    type: "pieChart",
    width: 4,
    chartType: "donut",
    chartTitle: "Is FDA Regulated Drug",
    dataTransformer: "pieChart",
    dataType: "byFDARegulatedDrug",
    showPercent: true,
    downloadableImage: true,
    downloadableCsv: true,
    legend: true,
    legendAnchor: "top-left",
    enableRadialLabels: false,
    filterBy: "is_fda_regulated_drug",
  },
  {
    type: "pieChart",
    width: 4,
    chartType: "donut",
    chartTitle: "FDA Regulated Device",
    dataTransformer: "pieChart",
    dataType: "byFDARegulatedDevice",
    showPercent: true,
    downloadableImage: true,
    downloadableCsv: true,
    legend: true,
    legendAnchor: "top-left",
    enableRadialLabels: false,
    filterBy: "is_fda_regulated_device",
  },
];

export const listBuild = [
   {
    type: "tableComponent",
    width: 12,
    dataTransformer: "tableComponent",
    chartTitle: "List Clinical Trials",
    dataType: "tableData",
    download: false,
    downloadableCsv: true,
    elevation: 0,
    filter: false,
    print: false,
    pagination: true,
    search: false,
    selectableRows: false,
    sort: true,
    rowsPerPageOptions: [10, 25, 50],
    viewColumns: false,
    serverSide: true,
    rowClick: true,
    idField: 'nct_id',
    showIdField: false,
    conversions: {
      start_date: (d) => utils.formatDate(d, `MM/DD/YYYY`),
    },
  },
]
