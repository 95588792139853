import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

//redux additions
import { connect } from "react-redux";

//component and helper imports
// import logo from "../../assets/img/acoerlogo.png";
import logo from "../../assets/img/hashlog_logo.png";
import userProfile from "../../assets/img/user.png";
import NavBarStyles from "../../assets/jss/Components/NavBarStyles";
import SideList from "./Sidebar/Sidebar";

//material-ui components
import IconButton from "@material-ui/core/IconButton";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { withStyles } from "@material-ui/core/styles";

export class NavBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			anchorElNotification: null,
			openNotification: false,
			anchorElApp: null,
			openApp: false,
			open: false,
			anchorEl: null,
			arrowRef: null,
			left: false,
			orgValue: "",
			invisible: false,
			noOrgOpen: false,
			subscriptionCheck: 0
		};
	}

	//function for the drawer
	toggleDrawer = (side, open) => () => {
		this.setState({
			[side]: open
		});
	};

	// function for opening the menu
	handleMenu = event => {
		const { currentTarget } = event;
		this.setState(state => ({
			anchorEl: currentTarget,
			open: !state.open
		}));
	};

	// function for closing the menu
	handleClose = () => {
		this.setState({
			anchorEl: null,
			open: false
		});
	};

	// Redirect to Home '/logout' route to revoke token and sign out completely
	async logout() {
		localStorage.clear();
		const redirectUrl = "/logout";
		window.location.replace(redirectUrl);
	}

	render() {
		const { classes, shared, profile, user } = this.props;
		const { anchorEl, open } = this.state;
		const id = open ? "simple-popper" : null;

		const profileArea = (
			<Paper className={classes.paperProfile}>
				<Grid container>
					<Grid item xs={2}>
						<img
							src={userProfile}
							alt="Profile large"
							className={classes.imageLarge}
						/>
					</Grid>
					<Grid item xs={10} style={{ paddingLeft: 15 }}>
						<Grid container justify="flex-end" style={{ paddingTop: 16 }}>
							<Button variant="outlined" align="right" onClick={this.logout}>
								Logout
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		);

		return (
			<AppBar
				position={"absolute"}
				color={"default"}
				className={classes.overRideShadow}
			>
				<Toolbar>
					<IconButton
						className={classes.menuButton}
						color="inherit"
						aria-label="Menu"
						onClick={this.toggleDrawer("left", true)}
					>
						<MenuIcon />
					</IconButton>
					<Typography variant={"h6"}>
						<Link to={"/"} className={classes.link}>
							<img src={logo} alt="logo" className={classes.image} />
						</Link>
					</Typography>
					{/* <Typography variant={"h6"}>Data Explorer</Typography> */}
					<SwipeableDrawer /*navbar styling wrapper*/
						open={this.state.left}
						onClose={this.toggleDrawer("left", false)}
						onOpen={this.toggleDrawer("left", true)}
					>
						<div
							tabIndex={0}
							role="button"
							onClick={this.toggleDrawer("left", false)}
							onKeyDown={this.toggleDrawer("left", false)}
						>
							<SideList selectedOrg={shared.org} />
						</div>
					</SwipeableDrawer>
					<div className={classes.flex} />
					{user && user.canAudit && (
						<Grid item>
							<Link to={"/dashboard"} style={{ textDecoration: "none" }}>
								<Button
									variant={"contained"}
									color={"primary"}
									style={{ borderRadius: 15, marginRight: 20 }}
								>
									<DashboardIcon style={{ paddingRight: 5 }} />
									Dashboard
								</Button>
							</Link>
						</Grid>
					)}
					{/*{profile.picture &&*/}
					<IconButton onClick={this.handleMenu} className={classes.buttonArea}>
						<img
							src={userProfile}
							alt="Profile"
							className={classes.profileImage}
						/>
					</IconButton>
					{/*}*/}
					<Popper
						id={id}
						open={open}
						anchorEl={anchorEl}
						transition
						placement="bottom-end"
						modifiers={{
							preventOverflow: {
								enabled: true,
								boundariesElement: "scrollParent"
							}
						}}
						style={{ marginTop: 14, zIndex: 2000 }}
					>
						{({ TransitionProps }) => (
							<Fade {...TransitionProps} timeout={350}>
								{profileArea}
							</Fade>
						)}
					</Popper>
				</Toolbar>
			</AppBar>
		);
	}
}

NavBar.propTypes = {
	classes: PropTypes.object.isRequired
};

export function mapStateToProps(state) {
	return {
		shared: state.shared,
		profile: state.profile
	};
}

export default connect(mapStateToProps, null)(withStyles(NavBarStyles)(NavBar));
