import React from "react";
import { render } from "react-dom";
import { Router, Switch, Route } from "react-router-dom";
import registerServiceWorker from "./registerServiceWorker";
import { Security, SecureRoute, ImplicitCallback } from "@okta/okta-react";
import config from "./components/Login/LoginAuth.config";

// Redux
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import history from "./components/Shared/Redux/history";
import {
	defaultStore,
	persistor
} from "./components/Shared/Redux/stores/defaultStore";

// Helpers
import httpService from "./api_client/interceptors";

// Components
import Notification from "./components/Notifications/Notification";
import Confirmation from "./components/Confirmation/Confirmation";
import LoginView from "./views/LoginView";
import LogoutView from "./views/LogoutView";
import DashboardView from "./views/DashboardView";
import HomeView from "./views/HomeView";
import NotFoundView from "./views/NotFoundView";
import theme from "./styles/theme";

// MUI
import LinearProgressIcon from "@material-ui/core/LinearProgress";
import { MuiThemeProvider } from "@material-ui/core/styles";
import TrialView from "./views/TrialView";

httpService.setupInterceptors(defaultStore, history);

function onAuthRequired({ history }) {
	history.push("/login");
}

render(
	<Provider store={defaultStore}>
		<PersistGate loading={<LinearProgressIcon />} persistor={persistor}>
			<Router history={history}>
				<MuiThemeProvider theme={theme}>
					<Security
						issuer={config.oidc.issuer}
						client_id={config.oidc.clientId}
						redirect_uri={config.oidc.redirectUri}
						onAuthRequired={onAuthRequired}
						pkce={true}
					>
						<Notification />
						<Confirmation />
						<Switch>
							<Route exact path="/login" component={LoginView} />
							<Route exact path="/logout" component={LogoutView} />
							<SecureRoute exact path="/" component={HomeView} />
							<SecureRoute exact path="/clinical/:id" component={TrialView} />
							<SecureRoute exact path="/:dashboard" component={DashboardView} />
							{/*<Route path component={NotFoundView}/>*/}
							<Route path="/implicit/callback" component={ImplicitCallback} />
						</Switch>
					</Security>
				</MuiThemeProvider>
			</Router>
		</PersistGate>
	</Provider>,
	document.getElementById("root")
);

registerServiceWorker();
