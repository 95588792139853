import React from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

// material ui
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const styles = {
  card: {
    maxWidth: 420,
  },
  disabled: {
    opacity: 0.5,
    '&:hover': {
      cursor: 'not-allowed'
    }
  },
  media: {
    height: 220,
    filter: 'blur(2px)'
  }
};

function HomeCard(props) {
  const {classes, dashboard} = props;

  const renderBody = () => {
    const body = <CardActionArea className={classNames({
      [classes.disabled]: dashboard.enabled === false
    })}>
      <CardMedia
        className={classes.media}
        image={dashboard.img || ''}
        title={dashboard.name}
      />
      <Divider/>
      <CardContent>
        <Grid container justify={'center'}>
          <Grid item>
            <Typography variant="h6">
              {dashboard.title}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </CardActionArea>;

  if(dashboard.enabled === false) {
    return body;
  }

  return <Link to={'/' + dashboard.name} style={{textDecoration: 'none', color: '#999999'}}>{body}</Link>;
  }

  return (
    <Card className={classNames({
      [classes.card]: true,
      [classes.disabled]: dashboard.enabled === false
    })}>
      {renderBody()}
    </Card>
  );
}

export default withStyles(styles)(HomeCard);
