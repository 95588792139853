import React from 'react';
import Dashboard from './Dashboard';

// material- ui
import Grid from '@material-ui/core/Grid';
import Filters from './Analytics/Filters/Filters';

export const DashboardWrapper = props => {

  return (
    <Grid container justify={'center'}>
      <Grid item xs={12}>
        <div style={{ marginLeft: -24}}>
          <Filters />
        </div>
        <Dashboard />
      </Grid>
    </Grid>
  );
};

export default DashboardWrapper;
