/**
 * Helper function that watches the authenticate state, then applies it
 * as a boolean (authenticated) as well as attaches the userinfo data.
 */
async function checkAuthentication() {
  const authenticated = await this.props.auth.isAuthenticated();

  if (authenticated !== this.state.authenticated) {
    if (authenticated) {
      this.setState({authenticated});
      const idToken = JSON.parse(localStorage.getItem('okta-token-storage'));
      this.setState({
        currentUserEmail: idToken.idToken.claims.preferred_username,
        currentUserName: idToken.idToken.claims.name,
      })
    } else {
      this.setState({authenticated});
    }
  }
}

export {checkAuthentication};