import React, {Component} from 'react';

// component and helper imports
import Breadcrumbs from '../Shared/Breadcrumbs';
import Dashboards from './DashboardHelper';
import DashboardStyles from '../../assets/jss/Components/DashboardStyles';

// material- ui
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import DashboardWrapper from './DashboardWrapper';

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardTitle: '',
      dashboardName: '',
    }
  }

  componentDidMount() {
    let url = window.location.href;
    for (let i = 0; i < Dashboards.length; i++) {
      if (url.includes(Dashboards[i].name)) {
        this.setState({
          dashboardTitle: Dashboards[i].title,
          dashboardName: Dashboards[i].name,
        })
      }
    }
  }

  render() {
    const {classes} = this.props;
    const {dashboardTitle, dashboardName} = this.state;

    //subnav containing breadcrumbs
    const subnav = (
      <Grid container alignItems={'center'} className={classes.subnav}>
        <Grid item style={{flexGrow: 1}}>
          <Breadcrumbs dashboardTitle={dashboardTitle} dashboardName={dashboardName}/>
        </Grid>
      </Grid>
    );

    return (
      <Grid container justify={'center'}>
        <Grid item xs={12}>
          {subnav}
        </Grid>
        <Grid item xs={12}>
          <DashboardWrapper/>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(DashboardStyles)(Dashboard);
