const NavBarStyles = theme => ({
	flex: {
		flexGrow: 1
	},
	profileImage: {
		borderRadius: "50%",
		width: 34,
		height: 34,
		opacity: 0.4
	},
	image: {
		verticalAlign: "middle",
		display: "inline",
		maxHeight: 34
	},
	imageLarge: {
		borderRadius: "50%",
		width: 96,
		height: 96,
		opacity: 0.4
	},
	paperProfile: {
		padding: 25,
		backgroundColor: "#fff"
	},
	select: {
		minWidth: 150,
		height: 40,
		marginLeft: 35
	},
	buttonArea: {
		padding: 5
	},
	paper: {
		paddingLeft: 30,
		paddingRight: 30,
		backgroundColor: "#f7f8f9"
	},
	paperApp: {
		paddingLeft: 15,
		paddingRight: 15,
		backgroundColor: "#f7f8f9"
	},
	noteHeader: {
		fontSize: 18,
		paddingTop: 12,
		paddingBottom: 12,
		fontWeight: 550,
		color: "#65656c"
	},
	noteHeaderApp: {
		fontSize: 18,
		paddingTop: 12,
		paddingBottom: 12,
		fontWeight: 450,
		color: "#65656c"
	},
	blankNote: {
		paddingTop: 60,
		paddingBottom: 80,
		color: "#65656c",
		fontSize: 16
	},
	appArea: {
		paddingBottom: 25,
		minWidth: 125
	},
	overRideShadow: {
		boxShadow: "none",
		// borderBottom: '1px solid #E0E0E2',
		backgroundColor: "#fff",
		maxHeight: 64
	},
	menuButton: {
		marginLeft: "-12px",
		marginRight: "20px"
	},
	right: {
		display: "block",
		position: "relative",
		float: "left"
	},
	link: {
		textDecoration: "none",
		color: "#000",
		paddingRight: 10
	},
	title: {
		marginLeft: 10,
		display: "inline",
		verticalAlign: "middle"
	}
});

export default NavBarStyles;
