import { combineReducers } from "redux";
import notification from "../../Notifications/NotificationsReducers";
import confirmation from "../../Confirmation/ConfirmationReducers";
import { shared, profile } from "../SharedReducers";
import { trial } from "../../Trial/TrialReducers";
import { dashboard } from "../../Dashboard/DashboardReducer";

//Root Reducer
const index = combineReducers({
	notification,
	confirmation,
	shared,
	profile,
	trial,
	dashboard,
});

export default index;
