import React, {Component} from 'react';
import Home from '../components/Home/Home';
import ContentWrapper from '../components/Shared/ContentWrapper';
import NavBar from '../components/Shared/NavBar';

//ui reflection of file view
export class HomeView extends Component {
  render() {
    return (
      <div>
        <NavBar/>
        <ContentWrapper>
          <Home/>
        </ContentWrapper>
      </div>
    );
  }
}

export default HomeView;