const DashboardStyles = theme => ({
  subnav: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingTop: 15,
    paddingBottom: 15
  }
});

export default DashboardStyles;