import axios from 'axios';

export default {
  setupInterceptors: () => {

    axios.interceptors.response.use(response => {
      return response;
    }, error => {
      // alert(error)

      if (error.response.status === 401) {
        // const returnUrl = window.location;
        // const redirectUrl = `${process.env.REACT_APP_DASHBOARD_URL}?redirect=` + returnUrl;
        // localStorage.clear();
        //
        // return window.location.assign(redirectUrl);
      }

      if (error.response.status === 403) {
        // const redirectUrl = process.env.REACT_APP_DASHBOARD_URL;
        // localStorage.clear();
        //
        // return window.location.assign(redirectUrl);
      }

      if (error.response.status === 404) {
        return
      }

      return Promise.reject(error);
    });
  },
};