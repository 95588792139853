
import clinical_dash from '../../assets/img/clinicaltrials_dash2.png';
import coronavirus_dash from '../../assets/img/vaxi-data-dash.png';
import clinicalTrialsCovid_dash from '../../assets/img/clinicaltrials-covid.png';
import vaers_dash from '../../assets/img/vaers-data-dash.png';

const Dashboards = [
  {
    name: 'clinicaltrials',
    title: 'Clinical Trials Explorer',
    url: '5dbef8dd022d9f002d74106a/?embed=true',
    img: clinical_dash,
    visible: true,
    enabled: true,
  },
  {
    name: 'vaxi-data',
    title: 'Covid-19 Vaccination Tracker',
    visible: true,
    img: coronavirus_dash,
  },
  {
    name: 'clinicaltrials-covid',
    title: 'Covid-19 Clinical Trials',
    visible: true,
    img: clinicalTrialsCovid_dash,
  },
  {
    name: 'opioid',
    title: 'Opioid Dashboard',
    url: '5e2f663eb9f4ea002ed684f1/?embed=true',
    visible: false,
  },
  {
    name: 'cti',
    title: 'CTI Africa',
    url: '5e2f6c20b9f4ea002ed684fa/?embed=true',
    visible: false,
  },
  {
    name: 'cti-health',
    title: 'CTI Africa',
    url: '5e41ef390d5b99002d8a1dac/?embed=true',
    visible: false,
  },
  {
    name: 'cti-malaria',
    title: 'Malaria Tracking Program',
    url: '5e49b6393b7cc4002d4acc36/?embed=true',
    visible: false,
  },
  {
    name: 'cti-vanilla',
    title: 'Vanilla Crop Tracking Program',
    url: '5e5fbbda12eaa0002df2eee2/?embed=true',
    visible: false,
  },
  {
    name: 'coronavirus',
    title: 'Coronavirus',
    url: '5e31b2e5e868c1002d7dd52e/?embed=true',
    visible: false,
  },
  {
    name: 'boehringer',
    title: 'Boehringer Lab Results',
    url: '5ea051ca46670b002d5ace06/?embed=true',
    visible: false,
  },
  {
    name: 'covid-defender',
    title: 'Covid Defender',
    url: '5ed64efaf2e642002dfd203e/?embed=true',
    visible: false,
  },
  {
    name: 'cdc-covid-data',
    title: 'CDC Covid Cases',
    url: '5f7c93f99b3010002d423435/?embed=true',
    visible: false,
  },
  {
    name: 'vaers-data',
    title: 'VAERS data',
    img: vaers_dash,
    visible: true,
  }
];

export default Dashboards;
