import React, { Component } from "react";
import ReactDOM from "react-dom";
import OktaSignIn from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import config from "./LoginAuth.config";
import logo from "../../assets/img/hashlog_logo.png";
import "../../styles/_login.css";

export default class LoginOktaSignInWidget extends Component {
	constructor(props) {
		super(props);
		this.widget = new OktaSignIn({
			baseUrl: config.oidc.issuer.split("/oauth2")[0],
			clientId: config.oidc.clientId,
			redirectUri: config.oidc.redirectUri,
			logo: logo,
			i18n: {
				en: {
					"primaryauth.title": "HashLog Login",
					// Errors
					"error.username.required": "Please enter your HashLog email"
				}
			},
			features: {
				// registration: true,
				smsRecovery: true,
				callRecovery: true,
				showPasswordToggleOnSignInPage: true,
				selfServiceUnlock: true
			},
			colors: {
				brand: "#4067AE"
			},
			// helpLinks: {
			//   help: 'https://www.acoer.io/support',
			// },
			// customButtons: [{
			//   title: 'Create an Account',
			//   className: 'btn-customAuth',
			//   click: function() {
			//     // clicking on the button navigates to another page
			//     window.location.href = `${process.env.REACT_APP_APP_URL}/register`;
			//   }
			// }],
			authParams: {
				responseType: ["token", "id_token"],
				issuer: config.oidc.issuer,
				display: "page",
				scopes: config.oidc.scope.split(" ")
			}
		});
	}

	componentDidMount() {
		const el = ReactDOM.findDOMNode(this);
		this.widget.renderEl({ el }, this.props.onSuccess, this.props.onError);
	}

	componentWillUnmount() {
		this.widget.remove();
	}

	render() {
		return <div />;
	}
}
