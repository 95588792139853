import React, { Component } from "react";

// component and helper imports
import Breadcrumbs from "../Shared/Breadcrumbs";
import Dashboards from "../Dashboard/DashboardHelper";
import DashboardCard from "./HomeCard";
import DashboardStyles from "../../assets/jss/Components/DashboardStyles";

// material- ui
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

export class Home extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { classes } = this.props;

		//subnav containing breadcrumbs
		const subnav = (
			<Grid container alignItems={"center"} className={classes.subnav}>
				<Grid item style={{ flexGrow: 1 }}>
					<Breadcrumbs />
				</Grid>
			</Grid>
		);

		return (
			<Grid container justify={"center"}>
				<Grid item xs={12}>
					{subnav}
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={3} justify="center" alignContent="stretch">
						{Dashboards.map(dashboard => {
							return (
								dashboard.visible ?
									<Grid item xs={6} md={3}>
										<DashboardCard dashboard={dashboard} />
									</Grid> : null
							);
						})}
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default withStyles(DashboardStyles)(Home);
