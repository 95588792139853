import React, { Component } from "react";
import NavBar from "../components/Shared/NavBar";
import Trial from "../components/Trial/Trial";

//ui reflection of file view
export class TrialView extends Component {
	render() {
		const { id } = this.props.match.params;

		return (
			<div>
				<NavBar />
				<Trial trialId={id} />
			</div>
		);
	}
}

export default TrialView;
