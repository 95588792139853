import axios from "axios";
import { showNotification } from "../Notifications/NotificationsActions";

const API_ROOT = process.env.REACT_APP_API_ROOT;

//========Action Types=========

export const TRIAL_VIEW_REQUEST = "TRIAL_VIEW_REQUEST";
export const TRIAL_VIEW_SUCCESS = "TRIAL_VIEW_SUCCESS";
export const TRIAL_VIEW_FAILURE = "TRIAL_VIEW_FAILURE";

//=========Thunks===========

export const viewTrial = trialId => dispatch => {
	const accessToken = JSON.parse(localStorage.getItem("okta-token-storage"))
		.accessToken.accessToken;

	dispatch({ type: TRIAL_VIEW_REQUEST });
	return axios({
		method: "GET",
		url: `${API_ROOT}/clinical-trial/view/${trialId}`,
		headers: {
			"Content-Type": "application/json;charset=UTF-8",
			Accept: "text/html",
			Authorization: "Bearer " + accessToken
		}
	})
		.then(response => {
			dispatch({
				type: TRIAL_VIEW_SUCCESS,
				payload: response.data
			});
		})
		.catch(error => {
			dispatch(
				showNotification(
					error.response.data.message ||
						"Oops! Something went wrong. Please try again.",
					"error"
				)
			);
			dispatch({
				type: TRIAL_VIEW_FAILURE,
				error
			});
		});
};
