import { dashboardBuild as vaxiBuild } from "./dashboards/vaxi-data/build";
import {
  queries,
  dependentQueries,
} from "./dashboards/vaxi-data/queries";

import { dashboardBuild as importBuild } from "./dashboards/clinicaltrials-covid/build";
import { queries as importQueries } from "./dashboards/clinicaltrials-covid/queries";

// Clinical Trials
import { 
  queries as trialsQueries,
  dependentQueries as trialDependentQueries,
  insightQueries,
  listQueries,
} from './dashboards/clinicaltrials/queries';
import { 
  dashboardBuild as clinicalTrialsDashboardBuild,
  insightsBuild,
  listBuild
} from "./dashboards/clinicaltrials/build";

// VAERS
import {
  dashboardBuild as vaersBuild,
  listBuild as vaersListBuild,
} from "./dashboards/vaers-data/build";
import {
  queries as vaersQueries,
  listQueries as vaersListQueries,
} from "./dashboards/vaers-data/queries";

export const dashboardBuild = (type) => {
  if (type === "vaxi-data") {
    const caseDashboard = {
      tabs: [
        {
          label: "Covid Vaccination Dashboard",
          description: '',
          builder: vaxiBuild,
          queries: queries,
          dependentQueries: dependentQueries,
        },
      ],
    };
    return caseDashboard;
  } else if (type === "clinicaltrials-covid") {
    const fileDashboard = {
      tabs: [
        {
          label: "Covid-19 Clinical Trials",
          description: '',
          builder: importBuild,
          queries: importQueries,
        },
      ],
    };
    return fileDashboard;
  } else if (type === "clinicaltrials") {
    const fileDashboard = {
      tabs: [
        {
          label: "Overview",
          description: '',
          builder: clinicalTrialsDashboardBuild,
          queries: trialsQueries,
          dependentQueries: trialDependentQueries
        },
        {
          label: "Insights",
          description: '',
          builder: insightsBuild,
          queries: insightQueries,
        },
        {
          label: "Trials List",
          description: '',
          builder: listBuild,
          queries: listQueries,
        }
      ],
    };
    return fileDashboard;
  } else if (type === "vaers-data") {
    return {
      tabs: [
        {
          label: "VAERS Dashboard",
          description: "",
          builder: vaersBuild,
          queries: vaersQueries,
        },
        {
          label: "List of VAERS entries",
          description: "",
          builder: vaersListBuild,
          queries: vaersListQueries,
        },
      ],
    }
  }
};
