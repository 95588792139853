import React, { Component } from "react";
import PropTypes from "prop-types";
import AcBreadcrumbs from "./Utils/breadcrumbUtil";

class Breadcrumbs extends Component {
	render() {
		// define breadcrumbs
		const { dashboardTitle, dashboardName, trialId } = this.props;

		const breadcrumbs = {
			home: {
				path: "/",
				title: "Home"
			},
			[dashboardName]: {
				path: "/" + dashboardName,
				title: dashboardTitle
			},
			clinical: {
				path: "/clinical",
				title: "Clinical Trials",
				breadcrumbs: {
					[trialId]: {
						path: "/" + trialId,
						title: trialId
					}
				}
			}
		};

		return (
			<div style={{ marginLeft: "-30px", marginTop: 5 }}>
				<AcBreadcrumbs
					breadcrumbs={breadcrumbs}
					defaultLink={"/"}
					home="Home"
				/>
			</div>
		);
	}
}

Breadcrumbs.propTypes = {
	projectId: PropTypes.string
};

export default Breadcrumbs;
