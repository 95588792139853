// actions that handles confirmation dialogs by performing stated action and showing confirmation message
export const types = {
  DISPLAY_CONFIRMATION_MESSAGE: "DISPLAY_CONFIRMATION_MESSAGE",
  HANDLE_CONFIRMATION_CLOSE: "HANDLE_CONFIRMATION_CLOSE",
};
// hides confirmation prompt after action is confirmed
export const hideConfirmation = () => ({
  type: types.HANDLE_CONFIRMATION_CLOSE,
});
// shows message confirming confirmation prompt was acted on with an affirmative response
export const showConfirmation = (title, message, confirmationType, id, userId) => ({
  type: types.DISPLAY_CONFIRMATION_MESSAGE,
  title,
  message,
  confirmationType,
  id,
  userId,
});
