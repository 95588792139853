import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/img/developed_by_acoer.svg";

// material-ui
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

// material-ui icons
import BuildIcon from "@material-ui/icons/Build";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import LockIcon from "@material-ui/icons/Lock";
import DashboardIcon from "@material-ui/icons/DashboardTwoTone";
import MarketplaceIcon from "@material-ui/icons/LocationCityTwoTone";
import HomeIcon from "@material-ui/icons/HomeTwoTone";

const sidebarStyles = {
	listItem: {
		marginTop: 5,
		marginRight: 60,
		paddingLeft: 15
	},
	linkStyle: {
		textDecoration: "none",
		color: "#000"
	},
	sideBar: {
		minHeight: "100vh"
	},
	logo: {
		width: 80
	},
	footer: {
		padding: 10
	},
	text: {
		paddingRight: 10
	}
};

//creating sidebar component
export class SideBar extends Component {
	render() {
		const { classes } = this.props;
		return (
			<Grid
				container
				direction={"column"}
				justify={"space-between"}
				alignItems={"center"}
				className={classes.sideBar}
			>
				<Grid item>
					<List>
						<Link to="/" className={classes.linkStyle}>
							<ListItem className={classes.listItem} disableGutters button>
								<ListItemIcon>
									<HomeIcon />
								</ListItemIcon>
								<ListItemText
									secondary="Home"
									secondaryTypographyProps={{ color: "#000" }}
								/>
							</ListItem>
						</Link>
						<Divider />
						<ListItem
							className={classes.listItem}
							disableGutters
							button
							disabled
						>
							<ListItemIcon>
								<BuildIcon />
							</ListItemIcon>
							<ListItemText
								secondary="General"
								secondaryTypographyProps={{ color: "#000" }}
							/>
						</ListItem>
						<ListItem
							className={classes.listItem}
							disableGutters
							button
							disabled
						>
							<ListItemIcon>
								<PermIdentityIcon />
							</ListItemIcon>
							<ListItemText
								secondary="Profile"
								secondaryTypographyProps={{ color: "#000" }}
							/>
						</ListItem>
						<ListItem
							className={classes.listItem}
							disableGutters
							button
							disabled
						>
							<ListItemIcon>
								<LockIcon />
							</ListItemIcon>
							<ListItemText
								secondary="Security"
								secondaryTypographyProps={{ color: "#000" }}
							/>
						</ListItem>
					</List>
				</Grid>
				<Grid item>
					<img src={logo} alt="" className={classes.logo}/>
				</Grid>
			</Grid>
		);
	}
}

export default withStyles(sidebarStyles)(SideBar);
