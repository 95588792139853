import covidFilters from "./dashboards/vaxi-data/filters";
import covidTrialFilters from "./dashboards/clinicaltrials-covid/filters";
import trialFilters from "./dashboards/clinicaltrials/filters";


const filters = (type) => {
  if (type === "vaxi-data") {
    return covidFilters;
  } else if (type === "clinicaltrials-covid") {
    return covidTrialFilters;
  } else if (type === "clinicaltrials") {
    return trialFilters;
  }
};

export default filters;
