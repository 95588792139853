const fields = [
  {
    id: "study_type",
    type: "list",
    label: "Study Type",
  },
  {
    id: "phases",
    type: "list",
    label: "Phase",
  },
  {
    id: "status",
    type: "list",
    label: "Clinical Trial Status",
  },
  {
    id: "lead_sponsor",
    type: "typeahead",
    label: "Lead Sponsor",
  },
];

export default fields;
