import React from 'react';
import Dashboard from '../components/Dashboard';
import ContentWrapper from '../components/Shared/ContentWrapper';
import NavBar from '../components/Shared/NavBar';
import { DashboardContext } from "@acoer/ac-js-lib-charts";

//ui reflection of file view
export const DashboardView = (props) => {
  const { dashboard } = props.match.params;

    return (
      <div>
        <NavBar/>
        <ContentWrapper>
          <DashboardContext.Provider value={{ dashboardId: dashboard }}>
            <Dashboard />
          </DashboardContext.Provider>
        </ContentWrapper>
      </div>
    );
}

export default DashboardView;