import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';

//ui reflection of file view
export class NotFoundView extends Component {
  render() {
    return (
      <Grid container justify={'center'}>
        <Grid item>
          404
          {/*<img src={NotFoundImage} alt=""/>*/}
        </Grid>
      </Grid>
    );
  }
}

export default NotFoundView;