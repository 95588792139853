import * as types from './SharedActions';

//reducer for shared actions
export const shared = (state = [], action) => {
  switch (action.type) {

    case types.INTROSPECTION_REQUEST:
      return {...state, introIsLoading: true};

    case types.INTROSPECTION_SUCCESS:
      return {...state, introIsLoading: false};

    case types.INTROSPECTION_FAILURE:
      return {...state, introIsLoading: false};

    case types.REVOCATION_REQUEST:
      return {...state, revocationIsLoading: true};

    case types.REVOCATION_SUCCESS:
      return {...state, revocationIsLoading: false};

    case types.REVOCATION_FAILURE:
      return {...state, revocationIsLoading: false};

    default:
      return state;
  }
};
//Separate reducer for profile pic
export const profile = (state = [], action) => {
  switch (action.type) {
    case types.GET_PICTURE_REQUEST:
      return {...state, picIsLoading: true};

    case types.GET_PICTURE_SUCCESS:
      return {...state, picture: action.payload, picIsLoading: false};

    default:
      return state;
  }
};