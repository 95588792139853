import React, { useCallback, useContext } from "react";

import { DashboardContext, FilterBar } from "@acoer/ac-js-lib-charts";
import filters from "../helpers/filters";
import Sticky from "react-sticky-el";

//redux
import { bindActionCreators } from "redux";
import * as dashboardActions from "../../DashboardActions";
import * as confirmActions from "../../../Confirmation/ConfirmationActions";
import { connect } from "react-redux";

const Filters = ({ actions, confirmActions, filterValues, savedFilters }) => {
  
  const { dashboardId } = useContext(DashboardContext);

  const filterDefinitions = filters(dashboardId);

  const getFilterValues = useCallback(
    (value, filterId, isTypeahead) => {
      actions.getFilterValues(dashboardId, value, filterId, isTypeahead, dashboardId === 'clinicaltrials');
    },
    [actions, dashboardId]
  );

  const handleRemoveFilter = useCallback(
    (id) => {
      const newFilters = { ...savedFilters };
      delete newFilters[id];
      confirmActions.showConfirmation(
        "Confirm",
        "Are you sure you want to remove this filter?",
        "removeFilter",
        newFilters
      );
    },
    [confirmActions, savedFilters]
  );

  const handleClearFilter = useCallback(() => {
    confirmActions.showConfirmation(
      "Confirm",
      "Are you sure you want to remove this filter?",
      "removeFilter",
      {}
    );
  }, [confirmActions])

  const setFilters = useCallback(
    (filters) => {
      actions.setFilters(filters);
    },
    [actions]
  );

  return (
    <DashboardContext.Provider
      value={{
        filters: filterDefinitions,
      }}
    >
      <div style={{ width: "calc(100vw - 24px" }}>
        <Sticky stickyStyle={{ zIndex: 1040 }}>
          <FilterBar
            filterValues={filterValues}
            savedFilters={savedFilters}
            getFilterValues={getFilterValues}
            onHandleRemoveFilter={handleRemoveFilter}
            onClearFilter={handleClearFilter}
            setFilters={setFilters}
          />
        </Sticky>
      </div>
      
    </DashboardContext.Provider>
  );
};

function mapStateToProps(state) {
  return {
    filterValues: state.dashboard.filters || {},
    savedFilters: (state.dashboard.filter && state.dashboard.filter.data) || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(dashboardActions, dispatch),
    confirmActions: bindActionCreators(confirmActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
