import utils from '../../../../../Shared/Utils/utils';

export const queries = {
  reportCounts: {
    addCount: true,
    limit: 0,
  },
  responsibles: {
    groupBy: ['responsible'],
    unwind: ['responsible'],
    limit: 0,
  },
  yearTrials: {
    addCount: true,
    limit: 0,
    initialFilter: {
      start_date: {
        type: 'TEMPORAL',
        gte: `${new Date().getFullYear()}-01-01`,
        lte: `${utils.formatDate(new Date(), 'YYYY-MM-DD')}`
      }
    },
  },
  timeline: {
    addCount: true,
    groupBy: ['start_date'],
    unwind: ['start_date'],
    limit: 0,
    initialFilter: {
      start_date: {
        type: 'TEMPORAL',
        // gte: `${new Date().getFullYear()}-01-01`,
        gte: `2020-01-02`,
        lte: `${utils.formatDate(new Date(), 'YYYY-MM-DD')}`,
      }
    },
  },
  topCountries: {
    addCount: true,
    groupBy: ['country'],
    unwind: ['country'],
    limit: 10,
    sortAsc: false,
    sortField: 'COUNT',
  },
  byCondition: {
    addCount: true,
    groupBy: ['condition'],
    unwind: ['condition'],
    limit: 75,
  },
};

export const insightQueries =  {
  phaseByStatus: {
    addCount: true,
    groupBy: ['phase', 'overall_status'],
    unwind: ['phase', 'overall_status'],
    limit: 0,
  },
  studyTypeByStatus: {
    addCount: true,
    groupBy: ['study_type', 'overall_status'],
    unwind: ['study_type', 'overall_status'],
    limit: 0,
  },
  byCountryAndType: {
    addCount: true,
    groupBy: ['country', 'study_type'],
    unwind: ['country', 'study_type'],
    limit: 0,
  },
  byPhase: {
    addCount: true,
    groupBy: ['phase'],
    unwind: ['phase'],
    limit: 0,
  },
  byStatus: {
    addCount: true,
    groupBy: ['overall_status'],
    unwind: ['overall_status'],
    limit: 0,
  },
  byStudyType: {
    addCount: true,
    groupBy: ['study_type'],
    unwind: ['study_type'],
    limit: 0,
  },
  byDMC: {
    addCount: true,
    groupBy: ['has_dmc'],
    unwind: ['has_dmc'],
    limit: 0,
  },
  byFDARegulatedDrug: {
    addCount: true,
    groupBy: ['is_fda_regulated_drug'],
    unwind: ['is_fda_regulated_drug'],
    limit: 0,
  },
  byFDARegulatedDevice: {
    addCount: true,
    groupBy: ['is_fda_regulated_device'],
    unwind: ['is_fda_regulated_device'],
    limit: 0,
  },
};

export const listQueries = {
  reportCounts: {
    addCount: true,
    limit: 0,
  },
  tableData: {
    fields: ['start_date', "completion_date", "brief_title", "nct_id", "overall_status", "study_type", "phase", "transactionId", 'source', 'responsible'],
    limit: 10,
    from: 0,
    sortAsc: false,
    sortField: "start_date",
  },
};

export const dependentQueries = {};
