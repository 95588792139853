import { types } from "./ConfirmationActions";

export const initialState = {
  title: null,
  message: null,
  confirmationType: null,
  visible: false,
  id: null,
  userId: null,
};

export default function confirmation(state = initialState, action) {
  switch (action.type) {
    case types.DISPLAY_CONFIRMATION_MESSAGE: {
      return {
        ...state,
        title: action.title,
        message: action.message,
        confirmationType: action.confirmationType,
        id: action.id,
        userId: action.userId,
        visible: true,
      };
    }

    case types.HANDLE_CONFIRMATION_CLOSE: {
      return {
        ...state,
        title: "",
        message: "",
        confirmationType: "",
        visible: false,
        id: "",
        userId: "",
      };
    }

    default:
      return state;
  }
}
