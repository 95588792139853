import React, { Component } from "react";
import PropTypes from "prop-types";

// redux additions
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as confirmActions from "./ConfirmationActions";
import * as dashboardActions from "../Dashboard/DashboardActions";

// material-ui components
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import history from "../Shared/Redux/history";

// actions taken upon confirmation/cancellation of an action
export class ConfirmationDialog extends Component {
  // cancelling an action event response
  handleCancel = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.props.actions.hideConfirmation();
  };

  // confirming an action event response
  handleOk = (event) => {
    const { confirmation, actions } = this.props;
    if (event) {
      event.preventDefault();
    }
    if (confirmation.confirmationType === "removeFilter") {
      this.props.dashboardActions.setFilters(confirmation.id);
    }
    actions.hideConfirmation();
  };

  render() {
    const { confirmation } = this.props;

    return (
      <Dialog
        open={confirmation.visible}
        onClose={this.handleClose}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
      >
        <DialogTitle id="confirmation-dialog-title">{confirmation.title}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{confirmation.message}</Typography>
        </DialogContent>
        <DialogActions>
          <div>
            <Button onClick={this.handleCancel} color="primary" style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={this.handleOk} color="primary">
              Ok
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

ConfirmationDialog.propTypes = {
  confirmation: PropTypes.object,
};

ConfirmationDialog.defaultProps = {
  confirmation: {
    title: null,
    message: null,
    confirmationType: null,
    visible: false,
  },
};

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: "80%",
    maxHeight: 435,
  },
});

export function mapStateToProps(state) {
  return {
    ...state,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(confirmActions, dispatch),
    dashboardActions: bindActionCreators(dashboardActions, dispatch),
  };
}

// middleware
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConfirmationDialog));
