import * as types from "./TrialActions";

//reducer for shared actions
export const trial = (state = [], action) => {
	switch (action.type) {
		case types.TRIAL_VIEW_REQUEST:
			return { ...state, loading: true };

		case types.TRIAL_VIEW_SUCCESS:
			return { ...state, loading: false, view: action.payload };

		case types.TRIAL_VIEW_FAILURE:
			return { ...state, loading: false };

		default:
			return state;
	}
};
