import axios from 'axios';
import {showNotification} from "../Notifications/NotificationsActions";

const API_ROOT = process.env.REACT_APP_API_ROOT;

//========Action Types=========

export const INTROSPECTION_REQUEST = "INTROSPECTION_REQUEST";
export const INTROSPECTION_SUCCESS = "INTROSPECTION_SUCCESS";
export const INTROSPECTION_FAILURE = "INTROSPECTION_FAILURE";

export const REVOCATION_REQUEST = "REVOCATION_REQUEST";
export const REVOCATION_SUCCESS = "REVOCATION_SUCCESS";
export const REVOCATION_FAILURE = "REVOCATION_FAILURE";

export const GET_PICTURE_REQUEST = "GET_PICTURE_REQUEST";
export const GET_PICTURE_SUCCESS = "GET_PICTURE_SUCCESS";

//=========Thunks===========

export const introspectToken = () => (dispatch) => {
  const accessToken = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken;

  const returnUrl = window.location;
  const redirectUrl = `${process.env.REACT_APP_DASHBOARD_URL}?redirect=` + returnUrl;

  dispatch({type: INTROSPECTION_REQUEST});
  return axios({
    method: 'GET',
    url: API_ROOT + '/app/user/introspect',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    }
  })
    .then(response => {
      dispatch({
        type: INTROSPECTION_SUCCESS,
        payload: response.data
      });
    })
    .catch((error) => {
      window.location.replace(redirectUrl);
      console.log(error);
    })
};
// synchronous call to revoke auth. token when logged out
export const revokeToken = () => (dispatch) => {
  const accessToken = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken;

  dispatch({type: REVOCATION_REQUEST});
  return axios({
    method: 'POST',
    url: API_ROOT + '/app/user/revoke',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    }
  })
    .then(response => {
      dispatch(showNotification("Successfully logged out", "success"));
      dispatch({
        type: REVOCATION_SUCCESS,
        payload: response.data
      });
    })
    .catch(error => {
      dispatch(
        showNotification(
          error.response.data.message || "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
      if (error.response) {
        console.log(error.response.data);
      }
    });
};

export const getPicture = () => (dispatch) => {
  const accessToken = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken;

  // Set it loading
  dispatch({
    type: GET_PICTURE_REQUEST
  });
  // Make the request
  return axios({
    method: "GET",
    url: API_ROOT + "/app/user/pic",
    headers: {
      'Content-Type': 'multipart/mixed',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    },
    responseType: 'blob'
  })
    .then(response => {
      let blob = new Blob([response.data], {type: ""});
      let url = URL.createObjectURL(blob);
      let img = new Image();
      img.src = url;
      dispatch({
        type: GET_PICTURE_SUCCESS,
        payload: img.src
      })
    })
    .catch(error => {
      if (error.response) {
        console.log(error.response.data);
      }
    });
};