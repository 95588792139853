export const queries = {
  reportCounts: {
    addCount: true,
    limit: 0,
  },
  byStatus: {
    addCount: true,
    groupBy: ['status'],
    limit: 0,
  },
  byPhase: {
    addCount: true,
    groupBy: ['phases'],
    limit: 0,
  },
  byStudyTypePerPurpose: {
    addCount: true,
    groupBy: ['study_type', 'primary_purpose'],
    limit: 0,
  },
  byStudyType: {
    addCount: true,
    groupBy: ['study_type'],
    limit: 0,
  },
  byResults: {
    addCount: true,
    groupBy: ['study_results'],
    limit: 0,
  },
  byFunders: {
    addCount: true,
    groupBy: ['funded_bys', 'phases'],
    limit: 0,
  },
  startTimeline: {
    addCount: true,
    groupBy: ['start_date'],
    limit: 0,
  },
  tableData: {
    fields: ['start_date', "completion_date", "title", "url", "status", "study_type", "phases", "transactionId", 'lead_sponsor'],
    limit: 10,
    from: 0,
    sortAsc: false,
    sortField: "start_date",
  },
};

export const dependentQueries =  {};
