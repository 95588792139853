import axios from "axios";
import FileDownload from "js-file-download";
import {
  DASHBOARD_DATA_REQUEST,
  DASHBOARD_DATA_SUCCESS,
  DASHBOARD_SINGLE_DATA_REQUEST,
  DASHBOARD_SINGLE_DATA_SUCCESS,
  DASHBOARD_DATA_FAILURE,
  DASHBOARD_SET_FILTER,
  DASHBOARD_RESET,
  DASHBOARD_FILTER_REQUEST,
  DASHBOARD_FILTER_SUCCESS,
  DASHBOARD_FILTER_FAILURE,
  DASHBOARD_DOWNLOAD_REQUEST,
  DASHBOARD_DOWNLOAD_SUCCESS,
  DASHBOARD_ADD_FILTER,
} from "./DashboardReducer";

export const requestFileDownload = (id) => ({
  type: DASHBOARD_DOWNLOAD_REQUEST,
  id,
});

export const successFileDownload = (id, name, payload) => {
  FileDownload(payload, name);
  return {
    type: DASHBOARD_DOWNLOAD_SUCCESS,
    id,
  };
};

const API_ROOT = process.env.REACT_APP_API_ROOT;

//= ========Thunks===========

export const getDashboardData = (
  dashboardId,
  dataType,
  fields,
  addCount,
  groupBy,
  limit,
  sortAsc,
  sortField,
  where,
  startFrom,
  text
) => async (dispatch) => {
  const { accessToken } = JSON.parse(localStorage.getItem("okta-token-storage")).accessToken;

  dispatch({ type: DASHBOARD_SINGLE_DATA_REQUEST, dataType });

  return await axios({
    method: "POST",
    url: `${API_ROOT}/${dashboardId}/query`,
    data: {
      addCount,
      groupBy,
      fields,
      limit,
      sortAsc,
      sortField,
      where,
      from: startFrom,
      text
    },
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => {
      dispatch({
        type: DASHBOARD_SINGLE_DATA_SUCCESS,
        payload: response.data,
        dataType,
        fips: dashboardId,
      });
      return response.data;
    })
    .catch((error) => {
      dispatch({ type: DASHBOARD_DATA_FAILURE, error });
    });
};

export const getDashboardDataMultiple = (dashboardId, queries) => async (dispatch) => {
  const { accessToken } = JSON.parse(localStorage.getItem("okta-token-storage")).accessToken;

  dispatch({ type: DASHBOARD_DATA_REQUEST });

  return await axios({
    method: "POST",
    url: `${API_ROOT}/${dashboardId}/query/multiple`,
    data: queries,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => {
      dispatch({
        type: DASHBOARD_DATA_SUCCESS,
        payload: response.data,
        fips: dashboardId,
      });
      return response.data;
    })
    .catch((error) => {
      dispatch({ type: DASHBOARD_DATA_FAILURE, error });
    });
};

export const getFilterValues = (dashboardId, id, filterId, typeahead, needUnwind) => {
  const thunk = (dispatch, getState) => {
    dispatch({ type: DASHBOARD_FILTER_REQUEST, filterId: typeahead ? filterId : id });
  
    const { accessToken } = JSON.parse(localStorage.getItem("okta-token-storage")).accessToken;
    let data;
    if (typeahead) {
      data = {
        groupBy: [filterId],
        limit: 10,
        sortAsc: true,
        sortField: filterId,
        unwind: needUnwind ? [filterId] : undefined,
        where: {
          [filterId]: {
            type: "STRING",
            like: id,
          },
        },
      };
    } else {
      data = {
        groupBy: [id],
        limit: 0,
      };
    }
  
    axios({
      method: "POST",
      url: `${API_ROOT}/${dashboardId}/query`,
      data: data,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        dispatch({
          type: DASHBOARD_FILTER_SUCCESS,
          payload: response.data,
          filterId: typeahead ? filterId : id,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: DASHBOARD_FILTER_FAILURE, error });
      });
  }
  
  thunk.meta = {
    debounce: {
      time: 500,
      key: "filter-values-lookup",
    },
  };
  return thunk;
};

export const setFilters = (filters) => (dispatch) => {
  dispatch({ type: DASHBOARD_SET_FILTER, payload: filters });
};

export const addFilter = (filters) => (dispatch) => {
  dispatch({ type: DASHBOARD_ADD_FILTER, payload: filters });
};

export const reset = () => (dispatch) => {
  dispatch({ type: DASHBOARD_RESET });
};

export const exportDashboardData = (queryType, dataType, query) => async (dispatch) => {
  const { accessToken } = JSON.parse(localStorage.getItem("okta-token-storage")).accessToken;

  dispatch(requestFileDownload(dataType));

  return await axios({
    method: "POST",
    url: `${API_ROOT}/${queryType}/export`,
    data: query,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "text/plain",
      Authorization: `Bearer ${accessToken}`,
    },
    responseType: "blob",
  })
    .then((response) => {
      dispatch(successFileDownload(dataType, dataType + ".csv", response.data));
      return response.data;
    })
    .catch((error) => {
      dispatch({ type: DASHBOARD_DATA_FAILURE, error });
    });
};
