//= =======Action Types=========

export const DASHBOARD_DATA_REQUEST = "DASHBOARD_DATA_REQUEST";
export const DASHBOARD_DATA_SUCCESS = "DASHBOARD_DATA_SUCCESS";
export const DASHBOARD_SINGLE_DATA_REQUEST = "DASHBOARD_SINGLE_DATA_REQUEST";
export const DASHBOARD_SINGLE_DATA_SUCCESS = "DASHBOARD_SINGLE_DATA_SUCCESS";
export const DASHBOARD_DATA_FAILURE = "DASHBOARD_DATA_FAILURE";

export const DASHBOARD_FILTER_REQUEST = "DASHBOARD_FILTER_REQUEST";
export const DASHBOARD_FILTER_SUCCESS = "DASHBOARD_FILTER_SUCCESS";
export const DASHBOARD_FILTER_FAILURE = "DASHBOARD_FILTER_FAILURE";
export const DASHBOARD_SET_FILTER = "DASHBOARD_SET_FILTER";
export const DASHBOARD_ADD_FILTER = "DASHBOARD_ADD_FILTER";

export const DASHBOARD_RESET = "DASHBOARD_RESET";

export const DASHBOARD_DOWNLOAD_REQUEST = "DASHBOARD_DOWNLOAD_REQUEST";
export const DASHBOARD_DOWNLOAD_SUCCESS = "DASHBOARD_DOWNLOAD_SUCCESS";
export const DASHBOARD_DOWNLOAD_FAILURE = "DASHBOARD_DOWNLOAD_FAILURE";

// reducer for shared actions
export const dashboard = (state = {}, action) => {
  switch (action.type) {

    case DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DASHBOARD_SINGLE_DATA_REQUEST:
      return {
        ...state,
        [action.dataType + "Loading"]: true,
      };

    case DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };

    case DASHBOARD_SINGLE_DATA_SUCCESS:
      return {
        ...state,
        [action.dataType + "Loading"]: false,
        [action.dataType]: action.payload,
      };

    case DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case DASHBOARD_FILTER_REQUEST:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.filterId]: {
            loading: true,
            error: null,
          },
        },
      };

    case DASHBOARD_FILTER_SUCCESS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.filterId]: {
            loading: false,
            values: action.payload.items.map((v) => v[action.filterId]),
          },
        },
      };

    case DASHBOARD_FILTER_FAILURE:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.filterId]: {
            loading: false,
            error: action.error,
          },
        },
      };

    case DASHBOARD_SET_FILTER:
      return {
        ...state,
        filter: {
          data: action.payload,
          filter_timestamp: Date.now(),
        },
      };

    case DASHBOARD_ADD_FILTER:
      return {
        ...state,
        filter: {
          data: {
            ...((state.filter && state.filter.data) || {}),
            ...action.payload
          },
          filter_timestamp: Date.now(),
        },
      };

    case DASHBOARD_DOWNLOAD_REQUEST:
      return {
        ...state,
        files: {
          [action.id]: {
            downloading: true,
            error: null,
          },
        },
      };
    case DASHBOARD_DOWNLOAD_SUCCESS:
      return {
        ...state,
        files: {
          [action.id]: {
            downloading: false,
            error: null,
          },
        },
      };

    case DASHBOARD_DOWNLOAD_FAILURE:
      return {
        ...state,
        files: {
          [action.id]: {
            downloading: false,
            error: action.error,
          },
        },
      };

    case DASHBOARD_RESET:
      return {};

    default:
      return state;
  }
};
