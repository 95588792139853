export const queries = {
  reportsCount: {
    addCount: true,
    limit: 0,
  },
  deathReports: {
    addCount: true,
    limit: 0,
    initialFilter: {
      DIED: {
        type: "STRING",
        eq: "Yes",
      },
    },
  },
  bySex: {
    addCount: true,
    limit: 0,
    groupBy: ["SEX"],
  },
  byDied: {
    addCount: true,
    limit: 0,
    groupBy: ["DIED"],
  },
  byRecovered: {
    addCount: true,
    limit: 0,
    groupBy: ["RECOVD"],
  },
  byManufacturer: {
    addCount: true,
    limit: 0,
    groupBy: ["VAX_MANU"],
  },
  byRoute: {
    addCount: true,
    limit: 0,
    groupBy: ["VAX_ROUTE"],
  },
  byAgeGroup: {
    addCount: true,
    limit: 0,
    groupBy: ["AGE_GROUP", "DIED"],
  },
};

export const listQueries = {
  reportCounts: {
    addCount: true,
    limit: 0,
  },
  tableData: {
    fields: [
      "RCVDATE",
      "AGE_GROUP",
      "SEX",
      "DIED",
      "RECOVD",
      "VAX_TYPE",
      "VAX_MANU",
      "VAX_NAME",
      "OTHER_MEDS",
      "CUR_ILL",
      "HISTORY",
      "PRIO_VAX",
      "ALLERGIES",
      "SYMPTOM_TEXT",
    ],
    limit: 10,
    from: 0,
    sortAsc: false,
    sortField: "RCVDATE",
  },
};

export const dependentQueries = {};
