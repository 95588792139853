import React, { useState, useCallback, useEffect, useContext, useMemo } from "react";

// component and helper imports
import DashboardStyles from "../../assets/jss/Components/DashboardStyles";
import { dashboardBuild } from "./Analytics/helpers//dashboardBuild";
import { getDate } from "./Analytics/helpers/utils";
import utils from "../Shared/Utils/utils";
import { Dashboard, DashboardContext } from "@acoer/ac-js-lib-charts";
import FreeTextSearch from "../Shared/FreeTextSearch";

// material- ui
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

// redux additions
import * as dashboardActions from "./DashboardActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const getWhere = (initial, where) =>
  (where &&
    Object.keys(where).reduce((accum, current) => {
      if (Object.keys(accum).includes(current) && where[current].type !== 'date') {
        accum[current] = {
          ...accum[current],
          [where[current].operator]: where[current].values,
        };
        return accum;
      }

      if (where[current].type === "date") {
        if (Array.isArray(where[current].values)) {
          accum[current] = {
            type: "TEMPORAL",
            gte: where[current].values[0],
            lte: where[current].values[1],
          };
        } else {
          accum[current] = {
            type: "TEMPORAL",
            gte: getDate(where[current].values),
          };
        }
      } else if (where[current].type === "NUMERICAL") {
        const isArrayOperator = Array.isArray(where[current].operator);
        if (isArrayOperator) {
          accum[current] = where[current].operator.reduce(
            (operatorAccum, op, idx) => ({
              ...operatorAccum,
              [op]: parseInt(where[current].values[idx]),
            }),
            {
              type: "NUMERICAL",
            }
          );
        } else {
          accum[current] = {
            type: "NUMERICAL",
            [where[current].operator]: parseInt(where[current].values),
          };
        }
      } else {
        accum[current] = {
          type: where[current].type || "STRING",
          [where[current].operator]: where[current].values,
        };
      }

      return accum;
    }, initial || {})) ||
  initial;

export const DashboardComp = (props) => {
  const { dashboard, savedFilters, actions } = props;
  const { dashboardId } = useContext(DashboardContext);
  const [tabValue, setTabValue] = useState(0);
  const [dependentData, setDependentData] = useState({});
  const [freeTextSearch, setFreeTextSearch] = useState('');

  const handleFreeTextSearchChange = (event) => {
    setFreeTextSearch(event.target.value);
  };

  const handleQuickSearch = () => {
    retrieveData(savedFilters || null, freeTextSearch);
  };

  const dependentQueries = useMemo(
    () => ({
      latestRecordedDate: () => ({
        topMeans:
        dashboard.lastRecordedDate.items.length > 0
        ? dashboard.lastRecordedDate.items[0].date
        : '2021-02-07',
      }),
      vaccinationPercent: () => ({
        latestRecordedDate:
        dashboard.lastRecordedDate.items.length > 0
        ? dashboard.lastRecordedDate.items[0].date
        : '2021-02-07',
      }),
    }),
    [dashboard]
  );

  const retrieveData = useCallback(
    (filter, freeTextSearch) => {

      let queryProp = {};
      let queries = { ...dashboardBuild(dashboardId).tabs[tabValue].queries };
      Object.keys(queries).forEach((query, index) => {
        const initialValue = queries[query]["initialFilter"];
        queryProp = {
          ...queryProp,
          [query]: {
            ...queries[query],
            where: getWhere({ ...initialValue }, filter && filter.data),
            text: freeTextSearch,
          },
        };
      });


      const depQuery = dashboardBuild(dashboardId).tabs[tabValue].dependentQueries || null;
      actions.getDashboardDataMultiple(dashboardId, queryProp).then((res) => {
        if (res && res.lastRecordedDate) {

          let depData = Object.keys(res).reduce((accum, key) => {
            if (queries[key].groupBy) {
              return {
                ...accum,
                [key]:
                  res[key].items.length > 0
                    ? res[key].items.map((value) => value[queries[key].groupBy[0]])
                    : ["none"],
              };
            }
            return accum;
          }, {});

          depData.latestRecordedDate = res.lastRecordedDate.items.length > 0
              ? res.lastRecordedDate.items[0].date
              : '2021-02-07';


          setDependentData(depData);

          let completeDepQuery = {};
          let depQueries = { ...depQuery };
          Object.keys(depQueries).forEach((query, index) => {
            const initialValue = depQueries[query]["initialFilter"];
            let initialCopy = { ...initialValue };
            const filterKeys = Object.keys(initialCopy);

            // loop through dependency filters to apply arrays to initial filter
            for (let i = 0; i < filterKeys.length; i++) {
              let nestedCopy = initialCopy[Object.keys(initialCopy)[i]];
              let key = filterKeys[i];

              nestedCopy = {
                ...nestedCopy,
                eq: depData[initialValue[key]["eq"]],
                in: depData[initialValue[key]["in"]],
              };
              initialCopy = {
                ...initialCopy,
                [key]: nestedCopy,
              };
            }

            completeDepQuery = {
              ...completeDepQuery,
              [query]: {
                ...depQueries[query],
                where: getWhere({ ...initialCopy }, filter && filter.data),
                text: freeTextSearch,
              },
            };
          });
          
          actions.getDashboardDataMultiple(
            dashboardId,
            completeDepQuery
          );
        }
      });
    },
    [actions, dashboardId, tabValue]
  );

  const onDownloadCsv = useCallback(
    (dataType, queries, depQueries, savedFilters) => {
      if (depQueries) {
        const initialValue = depQueries[dataType]["initialFilter"];
        let initialCopy = { ...initialValue };
        const filterKeys = Object.keys(initialCopy);

        // loop through dependency filters to apply arrays to initial filter
        for (let i = 0; i < filterKeys.length; i++) {
          let nestedCopy = initialCopy[Object.keys(initialCopy)[i]];
          const key = filterKeys[i];

          nestedCopy = {
            ...nestedCopy,
            in: dependentData[initialValue[key]["in"]],
            eq: dependentData[initialValue[key]["eq"]],
          };
          initialCopy = {
            ...initialCopy,
            [key]: nestedCopy,
          };
        }
        const filteredDepQuery = {
          ...depQueries[dataType],
          where: getWhere(null, { ...savedFilters }),
        };
        actions.exportDashboardData(dashboardId, dataType, filteredDepQuery);
      } else {
        const initialValue = queries[dataType]["initialFilter"];
        const filteredQuery = {
          ...queries[dataType],
          where: getWhere({ ...initialValue }, { ...savedFilters }),
        };
        actions.exportDashboardData(dashboardId, dataType, filteredQuery);
      }
    },
    [actions, dashboardId, dependentData]
  );

  const setFilters = useCallback(
    (filters) => {
      actions.addFilter(filters);
    },
    [actions]
  );

  const handleTabChange = useCallback(
    (newTab) => {
      setTabValue(newTab);
    },
    [setTabValue]
  );

  const handleTableData = useCallback(
    (id, fields, addCount, groupBy, limit, sortAsc, sortField, where, startFrom) => {
      actions.getDashboardData(
        dashboardId,
        id,
        fields,
        addCount,
        groupBy,
        limit,
        sortAsc,
        sortField,
        where,
        startFrom,
        freeTextSearch
      );
    },
    [actions, dashboardId, freeTextSearch]
  );

  const onTableRowClick = useCallback((data) => {
    if(dashboardId === 'clinicaltrials') {
      window.open(`https://www.clinicaltrials.gov/ct2/show/${data[0]}`, '_blank');
    } else if(dashboardId === 'clinicaltrials-covid') {
      window.open(data[0], '_blank')
    }
  }, [dashboardId]);


  useEffect(() => {
    retrieveData(savedFilters || null, freeTextSearch);
  }, [actions, retrieveData, savedFilters]);

  useEffect(() => {
    return () => actions.reset();
  }, [actions, dashboardId]);

  return (
    <div>
      <Grid container justify={'flex-end'}>
        <Grid item>
          <FreeTextSearch
            onHandleChange={handleFreeTextSearchChange}
            onHandleQuickSearch={handleQuickSearch}
            value={freeTextSearch}
          />
        </Grid>
      </Grid>
    <Grid container justify="center" style={{ marginTop: dashboardBuild(dashboardId).tabs.length > 1 ? -30 : 20 }}>
        <Grid item xs={11}>
          <Grid container spacing={1} justify="space-between">
            <Grid item xs={12}>
            <Grid container alignItems="center" spacing={6}>
                <Dashboard
                    dashboardData={dashboard}
                    dashboardBuild={dashboardBuild(dashboardId)}
                    dependentQueries={dependentQueries}
                    savedFilters={savedFilters && savedFilters.data}
                    tabChange={handleTabChange}
                    exportData={onDownloadCsv}
                    retrieveDashboardData={handleTableData}
                    setFilters={setFilters}
                    onTableRowClick={onTableRowClick}
                  />
                </Grid>
            </Grid>
          </Grid>
        </Grid>
    </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  savedFilters: state.dashboard.filter,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(dashboardActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(DashboardStyles)(DashboardComp));
