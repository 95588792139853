import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

export const FreeTextSearch = (props) => {
  const { value, onHandleChange, onHandleQuickSearch } = props;
    return (
        <TextField
            id="filled-search"
            label="Quick Text Search"
            type="search"
            variant="outlined"
            value={value}
            onChange={onHandleChange}
            style={{ width: 400 }}
            helperText={'Quick search across all data fields (does NOT filter by search term)'}
            onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                    onHandleQuickSearch();
                }
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={onHandleQuickSearch}>
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default FreeTextSearch;
