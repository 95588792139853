import React, {Component} from "react";
import {withAuth} from "@okta/okta-react";
import {Typography, Grid} from "@material-ui/core";

class LogoutView extends Component {
  constructor(props) {
    super(props);
  }

  // clears localstorage for all the access tokens before the initial render
  async componentWillMount() {
    this.props.auth.logout("/");
    localStorage.clear();
  }

  render() {
    return (
      <Grid container justify={'center'}>
        <Grid item>
          <Typography>Logging out...</Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withAuth(LogoutView);



