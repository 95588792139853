import React from "react";

export const dashboardBuild = [
  {
    type: "simpleCount",
    countTitle: "Total adverse events",
    width: 6,
    chartTitle: "",
    dataType: "reportsCount",
    dataTransformer: "simpleCount",
    main: "COUNT",
    includeChartTitle: false,
    height: 200,
  },
  {
    type: "simpleCount",
    countTitle: "How many died?",
    width: 6,
    chartTitle: "",
    dataType: "deathReports",
    dataTransformer: "simpleCount",
    main: "COUNT",
    height: 200,
  },
  {
    type: "pieChart",
    width: 3,
    chartTitle: "By Gender",
    dataTransformer: "pieChart",
    dataType: "bySex",
    showPercent: true,
    downloadableImage: true,
    downloadableCsv: true,
    legend: true,
    legendAnchor: "top-left",
    enableRadialLabels: false,
    filterBy: "SEX",
    height: 400,
  },
  {
    type: "pieChart",
    width: 3,
    chartTitle: "Patients Died",
    dataTransformer: "pieChart",
    dataType: "byDied",
    showPercent: true,
    downloadableImage: true,
    downloadableCsv: true,
    legend: true,
    legendAnchor: "top-left",
    enableRadialLabels: false,
    filterBy: "DIED",
    height: 400,
  },
  {
    type: "pieChart",
    width: 3,
    chartTitle: "Patients Recovered",
    dataTransformer: "pieChart",
    dataType: "byRecovered",
    showPercent: true,
    downloadableImage: true,
    downloadableCsv: true,
    legend: true,
    legendAnchor: "top-left",
    enableRadialLabels: false,
    filterBy: "RECOVD",
    height: 400,
  },
  {
    type: "pieChart",
    width: 3,
    chartTitle: "Vaccination Route",
    dataTransformer: "pieChart",
    dataType: "byRoute",
    showPercent: true,
    downloadableImage: true,
    downloadableCsv: true,
    legend: true,
    legendAnchor: "top-left",
    enableRadialLabels: false,
    filterBy: "VAX_ROUTE",
    height: 400,
  },
  {
    type: "wordCloud",
    width: 6,
    chartTitle: "Manufacturer",
    dataTransformer: "wordCloud",
    dataType: "byManufacturer",
    groupBy: "VAX_MANU",
    downloadableImage: true,
    downloadableCsv: true,
  },
  {
    type: "barChart",
    width: 6,
    chartTitle: "Age Group",
    layout: "horizontal",
    dataTransformer: (data, chart) => {
      let returnData = {};
      const sortByAgeGroup = (data) =>
        data.sort((a, b) => {
          if (a.groupedBy === "100+" && b.groupedBy !== "100+") {
            return 1;
          } else if (a.groupedBy !== "100+" && b.groupedBy === "100+") {
            return -1;
          }
          return a.groupedBy > b.groupedBy ? 1 : -1;
        });

      const keys = [];
      const transformedData = [];
      if (data && data.items && data.items.length > 0) {
        data.items.map((q, i) => {
          if (q[chart.groupBy]) {
            const index = transformedData.findIndex((i) => i.groupedBy === q[chart.groupBy]);
            if (index < 0) {
              const obj = {
                groupedBy: q[chart.groupBy],
                [chart.itemBy ? q[chart.itemBy] : "Count"]: chart.countField
                  ? q[chart.countField]
                  : q.COUNT,
              };
              transformedData.push(obj);
            } else {
              transformedData[index][q[chart.itemBy] || "Count"] = chart.countField
                ? q[chart.countField]
                : q.COUNT;
            }

            const key = chart.itemBy && q[chart.itemBy] ? q[chart.itemBy].toString() : "Count";
            if (!keys.includes(key)) {
              keys.push(key);
            }
          }
        });

        returnData = {
          data: sortByAgeGroup(transformedData),
          keys,
        };
      }

      return returnData;
    },
    dataType: "byAgeGroup",
    groupBy: "AGE_GROUP",
    downloadableImage: true,
    downloadableCsv: true,
    sortOnX: true,
    showBarValue: false,
    itemBy: "DIED",
  },
];

export const listBuild = [
  {
    type: "tableComponent",
    width: 12,
    dataTransformer: (data, chart) => {
      const columns = [];
      const tableData = [];
      const toTitleText = (text) => {
        const result = text.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
      };
      if (data && data.items && data.items.length > 0) {
        let keyNames = [];
        data.items.map((item, i) => {
          const columnNames = Object.keys(item);
          keyNames = Array.from(new Set([...keyNames, ...columnNames]));
        });
        const keys = keyNames.sort();
        keys.forEach((key) => {
          const splitString = key;
          let titleString = splitString.replace("_", " ");
          if (key === "_id") {
            titleString = "ID";
          }
          const column = {
            name: key,
            label: titleString,
            options: {
              display:
                (key === "_id" && !!!chart.idField) ||
                key === "_id" ||
                (chart.idField && key === chart.idField)
                  ? chart.showIdField || false
                  : true,
            },
          };
          columns.push(column);
        });

        if (chart.extraColumns) {
          Object.keys(chart.extraColumns).forEach((key) => {
            columns.push({
              name: key,
              label: chart.extraColumns[key].label || key,
            });
          });
        }

        data.items.forEach((d) => {
          const row = {
            ...d,
          };

          if (chart.conversions) {
            Object.keys(chart.conversions).forEach((k) => {
              row[k] = chart.conversions[k](d[k]);
            });
          }

          if (chart.extraColumns) {
            Object.keys(chart.extraColumns).forEach((k) => {
              row[k] = chart.extraColumns[k].content(d);
            });
          }

          if (chart.idField) {
            row._id = d[chart.idField];
          } else {
            delete row._id;
          }

          tableData.push(row);
        });
      }

      console.log("Columns", columns);
      const returnData = {
        columns,
        data: tableData,
      };

      return returnData;
    },
    chartTitle: "VAERS records",
    dataType: "tableData",
    download: false,
    downloadableCsv: true,
    elevation: 0,
    filter: false,
    print: false,
    pagination: true,
    search: false,
    selectableRows: false,
    sort: true,
    rowsPerPageOptions: [10, 25, 50],
    viewColumns: false,
    serverSide: true,
    rowClick: false,
    idField: "_id",
    showIdField: false,
  },
];
